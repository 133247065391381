import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import * as arraySupport from 'dayjs/plugin/arraySupport';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

export class DateParserFormatter implements NgbDateParserFormatter {
  private readonly FORMAT = 'DD.MM.YYYY';

  constructor() {
    dayjs.extend(arraySupport);
    dayjs.extend(customParseFormat);
  }

  parse(value: string): NgbDateStruct {
    let parsed = dayjs(value, this.FORMAT);
    const date = parsed.toDate();
    if (!parsed.isValid()) {
      return null;
    }
    return {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
  }

  format(value: NgbDateStruct): string {
    if (value == null) {
      return null;
    }

    let parsed = dayjs([value.year, value.month - 1, value.day]);
    if (parsed.isValid()) {
      return parsed.format(this.FORMAT);
    }
    return null;
  }
}
