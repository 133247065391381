<div class="input-group">
  <input class="form-control"
         placeholder="DD.MM.YYYY"
         name="datepicker"
         [ngModel]="model"
         [footerTemplate]="footerTemplate"
         #datepicker="ngbDatepicker"
         (ngModelChange)="modelChanged($event)"
         (dateSelect)="onSelect.emit()"
         [disabled]="disabled"
         ngbDatepicker/>
  <div class="input-group-append">
    <button class="btn btn-outline-primary" (click)="datepicker.toggle()">
      <i class="fa fa-calendar"></i>
    </button>
  </div>
</div>

<ng-template #footerTemplate>
  <hr class="my-0" />
  <button class="btn btn-primary btn-sm float-start footer-button" (click)="model = today; datepicker.navigateTo()">Heute</button>
</ng-template>
