import { Component, EventEmitter, Output } from "@angular/core";
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-datepicker",
  standalone: true,
  templateUrl: "./datepicker.component.html",
  styleUrls: ["./datepicker.component.scss"],
  imports: [FormsModule, NgbDatepickerModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatepickerComponent,
      multi: true,
    },
  ],
})
export class DatepickerComponent implements ControlValueAccessor {
  model: Date;
  disabled = false;

  today = new Date();

  @Output()
  onSelect = new EventEmitter();

  private onChange: (_: Date) => void | null;
  private onTouched: () => void | null;

  modelChanged(value: Date): void {
    this.model = value;
    if (this.onChange) {
      this.onChange(value);
    }
  }

  registerOnChange(fn: (_: Date) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: Date): void {
    if (!(value instanceof Date)) {
      value = new Date(value);
    }
    this.model = value;
  }
}
