<app-head-content>
  <div class="title-with-button d-flex flex-column flex-md-row">
    <h1>Glücksrad</h1>
    <div class="d-flex align-items-center mt-1 mt-md-0">
      <span class="text-muted d-none d-md-block" style="margin-right: .25rem">
        Status: <b>{{isActive() ? 'aktiv' : 'deaktiviert'}}</b>
      </span>
      <ui-switch class="d-flex align-items-center" [ngModel]="isActive()" size="small" (change)="statusChanged($event)"
                 [disabled]="!posExperience?.valid() || invalidOdds"></ui-switch>
      <div class="btn-group ml-1">
        <button class="btn btn-primary" (click)="saveAndExit()"
                [disabled]="!posExperience?.valid() || invalidOdds">
          Speichern &
          Schließen
        </button>
      </div>
    </div>
  </div>
</app-head-content>

<div class="row {{posExperience.config?.theme}}">
  <div class="col-12 col-xl-7 order-2 order-xl-1 prize-editors">
    <app-prize-editor [prize]="posExperience.config?.jackpot" [(relations)]="posExperience.relations" [invalidOdds]="invalidOdds" (sliceChange)="onChange()"
                      (onTurnRequest)="firstVisibleEditor.next([0])"
                      title="Hauptpreis" [highlight]="highlightedForm === 'jackpot'"
                      #jackpotEditor></app-prize-editor>
    <app-prize-editor [prize]="posExperience.config?.second" [(relations)]="posExperience.relations" [invalidOdds]="invalidOdds" (sliceChange)="onChange()"
                      (onTurnRequest)="firstVisibleEditor.next([4])"
                      title="2. Gewinnstufe" [highlight]="highlightedForm === 'second'"></app-prize-editor>
    <app-prize-editor [prize]="posExperience.config?.third" [(relations)]="posExperience.relations" [invalidOdds]="invalidOdds" (sliceChange)="onChange()"
                      (onTurnRequest)="firstVisibleEditor.next([2,6])"
                      title="3. Gewinnstufe" [highlight]="highlightedForm === 'third'"></app-prize-editor>
    <app-prize-editor [prize]="posExperience.config?.consolation" [(relations)]="posExperience.relations" (sliceChange)="onChange()"
                      (onTurnRequest)="firstVisibleEditor.next([1,3,5,7])"
                      title="Trostpreis" [highlight]="highlightedForm === 'consolation'"></app-prize-editor>
  </div>

  <div class="col-12 col-xl-5 order-1 order-xl-2">
    <div class="recurring-event-wrapper mb-2">
      <app-recurring-event-picker [(recurringEvents)]="posExperience.recurring_events"
                                  [rules]="['permanent', 'daily', 'weekly']"
                                  [title]="'Wähle Zeitraum aus:'"
                                  (recurringEventsChange)="onChange()"></app-recurring-event-picker>
    </div>
    <div class="sticky-container" #parent>
      <app-ipad-preview-container [parent]="parent" [divisor]="1350">
      <app-wheel-of-luck-game [wheelComponent]="wheel" [posExperience]="posExperience" (nextClicked)="onFinish()" #game>
        <app-wheel-of-luck [posExperience]="posExperience" [logo]="currentPartner.logo" [animations]="animations"
                           (onPrizeClick)="scrollIntoView($event)"
                           #wheel></app-wheel-of-luck>
      </app-wheel-of-luck-game>
      </app-ipad-preview-container>
      <div class="wheel-buttons">
        <div class="theme-switch">
          <span>Dunkles Design aktivieren</span>
          <ui-switch style="margin-top: -.4rem;" [ngModel]="posExperience.config?.theme === 'dark-theme'" (change)="toggleTheme()" size="small"></ui-switch>
        </div>

        <p class="mt-1 text-center small text-muted">Das Glücksrad erscheint, falls aktivert, pro Nutzer nur einmal pro Tag.</p>

        <p class="mt-1 px-2 text-center small text-muted">
          Text- und Bildmaterial für eine Kampagne zum Glücksrad haben wir hier bereitgestellt:
          <a href="https://drive.google.com/drive/folders/1OGDpapHukFiEaFMQJ0j-i_3OPgaYcL0w?usp=sharing" rel="noreferrer noopener" target="_blank">Herunterladen</a>
        </p>
      </div>
    </div>
  </div>

</div>
