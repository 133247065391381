import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DateTimePickerComponent } from "./date-time-picker.component";
import {
  NgbTimepickerModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { DatepickerComponent } from "../datepicker/datepicker.component";

@NgModule({
  declarations: [DateTimePickerComponent],
  exports: [DateTimePickerComponent],
  imports: [
    CommonModule,
    NgbTimepickerModule,
    NgbTooltipModule,
    FormsModule,
    DatepickerComponent,
  ],
})
export class DateTimePickerModule {}
