export const environment = {
  production: true,
  environment_name: 'staging',
  release: '4190a9db9208e9939d81bba8cad1e0066d1b8115',

  api_base_url: 'https://api.staging.mankidodev.de/merchantcenter/v1/',

  sentry_dsn: 'https://fa0dd696f338407d3bc6dd65d9a56285@sentry.mankido.net/4',
  sentry_project_id: '4',

  // configure which new features should be activated
  features: {
    special_points_events: true
  }
};
